import React from "react"
import PropTypes from "prop-types"
import ImageContainer from "./imageContainer"

const TwoComponentList = ({
  containerClass,
  imageClass,
  imageSrc,
  imageAlt,
  textClass,
  text,
}) => {
  return (
    <div className={containerClass}>
      <ImageContainer container={imageClass} src={imageSrc} alt={imageAlt} />
      <p className={textClass}>{text}</p>
    </div>
  )
}

TwoComponentList.propTypes = {
  containerClass: PropTypes.string.isRequired,
  imageClass: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  textClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default TwoComponentList
