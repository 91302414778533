import React from "react"
import Layout from "../components/layout"
import Cellphone from "../images/cellphoneHome.png"
import Cloud from "../images/cloud.png"
import ImageContainer from "../components/imageContainer"
import AppStore from "../images/appstore.png"
import PlayStore from "../images/playstore.png"
import Bottle from "../images/bottle.png"
import Change from "../images/change.png"
import Food from "../images/food.png"
import Play from "../images/play.png"
import Sleep from "../images/sleep.png"
import Breastfeed from "../images/breastfeed.png"
import CloudDown from "../images/cloudDown.png"
import Chart from "../images/chart.png"
import LineChart from "../images/linechart.png"
import Screen2 from "../images/screen2.png"
import TwoComponentList from "../components/twoComponentList"
import Footer from "../components/footer"

const Style = {
  SecondSectionListContainer: "flex w-full sm:w-48p mb-5 ",
  SecondSectionListImage: "w-24",
  SecondSectionListText:
    "leading-snug text-primary self-center frk-regular text-md w-10/12  pl-4 lg:text-lg xl:pl-8 sm:w-9/12  ",
}

const FirstSections = () => {
  return (
    <section className="gradientHome relative flex pt-32 sm:min-h-94 md:min-h-97 xl:min-h-100">
      <ImageContainer
        container="w-full absolute self-end"
        src={Cloud}
        alt="cloud"
      />
      <div className="relative w-full container  flex flex-col-reverse sm:flex-row">
        <ImageContainer
          container="mt-32 w-65  mx-auto sm:mt-0 sm:w-86 sm:pl-3 md:pl-0"
          src={Cellphone}
          alt="cellphone"
        />
        <div className="pt-2 sm:pt-24 sm:ml-8 sm:w-6/12 md:w-8/12 lg:pt-48 lg:ml-20 xl:w-7/12">
          <h1 className="text-white plus-bold text-5xl text-center sm:text-4xl sm:text-left  lg:text-5xl">
            Baby Blocks Tracker
          </h1>
          <p className="text-white frk-regular text-3xl sm:text-2xl mt-4 mb-8 sm:my-4  text-center sm:text-left">
            The customizable baby tracker app, with blocks that grow with your
            baby
          </p>
          <div className="flex justify-center md:justify-start">
            <a
              href="https://apps.apple.com/us/app/blocks-baby-tracker/id1474383328"
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <ImageContainer
                container="w-40 px-1 sm:w-32 md:w-40 md:mr-5 md:px-0 lg:mr-0 lg:w-48 lg:pr-3"
                src={AppStore}
                alt="Apple Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=co.hellobuild.babytracker"
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <ImageContainer
                container="w-40 px-1 sm:w-32 md:w-40 md:px-0 lg:w-48 lg:pl-3"
                src={PlayStore}
                alt="Google Play Store"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

const SecondSections = () => {
  return (
    <section className="mt-20 mb-10 lg:mt-40 container">
      <h3 className="plus-bold text-aqua text-center text-2xl  mb-16 lg:text-4xl  lg:mb-20">
        MODULAR BLOCKS
      </h3>
      <div className="w-full sm:flex sm:flex-wrap sm:justify-between">
        <TwoComponentList
          containerClass={Style.SecondSectionListContainer}
          imageClass={Style.SecondSectionListImage}
          imageSrc={Sleep}
          imageAlt="Sleep"
          textClass={Style.SecondSectionListText}
          text="Sleep: track naps and monitor progress to that (almost) full night sleep."
        />
        <TwoComponentList
          containerClass={Style.SecondSectionListContainer}
          imageClass={Style.SecondSectionListImage}
          imageSrc={Food}
          imageAlt="Food"
          textClass={Style.SecondSectionListText}
          text="Food: records type of meal, and portion in spoons, pouches or feeder."
        />
        <TwoComponentList
          containerClass={Style.SecondSectionListContainer}
          imageClass={Style.SecondSectionListImage}
          imageSrc={Breastfeed}
          imageAlt="Breastfeed"
          textClass={Style.SecondSectionListText}
          text="Breastfeeding: monitor nursing."
        />
        <TwoComponentList
          containerClass={Style.SecondSectionListContainer}
          imageClass={Style.SecondSectionListImage}
          imageSrc={Change}
          imageAlt="Change"
          textClass={Style.SecondSectionListText}
          text="Diapers: quick and easy control of changing times."
        />
        <TwoComponentList
          containerClass={Style.SecondSectionListContainer}
          imageClass={Style.SecondSectionListImage}
          imageSrc={Bottle}
          imageAlt="Bottle"
          textClass={Style.SecondSectionListText}
          text="Bottles: tracks volume and time of day."
        />
        <TwoComponentList
          containerClass={Style.SecondSectionListContainer}
          imageClass={Style.SecondSectionListImage}
          imageSrc={Play}
          imageAlt="Play"
          textClass={Style.SecondSectionListText}
          text="Medicines: schedule timers and be reminded of dosages."
        />
      </div>
    </section>
  )
}

const ThirdSection = () => {
  //terminar mañana con la nueva imagen
  return (
    <section className="purpleGradient">
      <ImageContainer
        container="w-full"
        src={CloudDown}
        alt="CloudDown"
        className="object-cover h-32 h-56 lg:h-auto"
      />
      <ImageContainer
        container="w-full absolute mt-86 md:mt-100 lg:mt-64 z-0 "
        src={Chart}
        alt="Chart"
        className="object-cover h-72 object-left sm:h-auto "
      />
      <div className="container flex flex-col lg:flex-row  lg:mt-16 justify-between relative ">
        <div className="mb-6 lg:mt-48 lg:mb-96 lg:w-5/12">
          <ImageContainer
            container="mx-auto lg:mx-0 w-40"
            src={LineChart}
            alt="Line Chart"
          />
          <div className="w-full text-center mt-8 lg:mt-3 mb-12 sm:mb-8 lg:mb-0 flex justify-center lg:justify-start w-7/12">
            <h3 className="text-3xl text-white plus-bold  lg:text-left sm:w-8/12 lg:w-full">
              Follow the statistic of your baby's activity
            </h3>
          </div>
        </div>
        <ImageContainer
          container="mx-auto w-65 mb-16 md:mb-40 lg:mb-0 sm:w-96b"
          src={Screen2}
          alt="Cellphone 2"
        />
      </div>
      <Footer />
    </section>
  )
}

const IndexPage = () => (
  <Layout title="Home" link="/">
    <main>
      <FirstSections />
      <SecondSections />
      <ThirdSection />
    </main>
  </Layout>
)

export default IndexPage
